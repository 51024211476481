import { Icon } from '@iconify/react';
import instagramIcon from '@iconify/icons-mdi/instagram';

function Footer(){
    return(
        <div className="footer">
            <div>
                <div className="center-foot">
                    <div className='foot-parallel'>
                        <div className="footer-column">
                            <a href="/" className="navItem inactive light ref" id="home">
                                Home
                            </a>
                            <a href="/club" className="navItem inactive light ref" id="home">
                                Verein
                            </a>
                            <a href="/training" className="navItem inactive light ref">
                                Training
                            </a>
                            <a href="/gallery" className="navItem inactive light ref">
                                Galerie
                            </a>
                            <a href="/spee" className="navItem inactive light ref">
                                Spee Cup
                            </a>
                            <a href="/sponsors" className="navItem inactive light ref">
                                Sponsoren
                            </a>
                            <a href="/contact" className="navItem inactive light ref">
                                Kontakt
                            </a>
                        </div>
                        <div className="verticalrow">
                            <div className="rowfill"/>
                        </div>
                        <div className="footer-column text">
                            GRC66 bei Social Media <br/><br/>
                            <a href='https://www.instagram.com/genthiner_radsportclub_66/' target='_blank' className='silentLink'><Icon icon={instagramIcon} width="24" inline={true}/> @ genthiner_radsportclub_66</a>
                        </div>
                    </div>
                    Copyright @ Genthiner Radsportclub 66 e.V. 2024 | Designed by Jan Jeschinski
                </div>
            </div>
        </div>
    );
}

export default Footer;