import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import Navigation from './navandfoot/Navigation';
import Welcome from './siteContent/Welcome';
import Club from './siteContent/Club';
import Footer from './navandfoot/Footer';
import Training from './siteContent/Training';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from './siteContent/Contact';
import Spee from './siteContent/Spee';
import Gallery from './siteContent/Gallery';
import Sponsors from './siteContent/Sponsors';

const root = ReactDOM.createRoot(document.getElementById('content'));

root.render(
  <React.StrictMode>
    <Navigation />
    <div className="ContentArea">
      <BrowserRouter>
        <Routes>
          <Route path="/club" element={<Club />}exact  />
          <Route path="/contact" element={<Contact />}exact  />
          <Route path="/gallery" element={<Gallery />}exact  />
          <Route path="/spee" element={<Spee />}exact  />
          <Route path="/sponsors" element={<Sponsors />}exact  />
          <Route path="/training" element={<Training />}exact  />
          <Route path="/*" element={<Welcome />}exact  />
        </Routes>
      </BrowserRouter>
    </div>
    <Footer />
  </React.StrictMode>
);

reportWebVitals();