import React from "react";

class AutoGallery extends React.Component{
    constructor(props){
        super();
        this.state = {
            data: [],
            inverted: props.inverted,
        }
        this.fetchData = this.fetchData.bind(this)
    }

    async componentDidMount(){
        await this.fetchData()
    }

    async fetchData(){
        await fetch(process.env.REACT_APP_CDN_URL+'media/auto/'+this.props.src+'/index.ska')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        return response.json();
        })
        .then((data) => {
            this.setState({
                data: data
            })
        })
        .catch((error) => {
            console.error('Error:', error);
        })
    }

    render(){
        let galBorder = (this.props.deleteMode)?{border:"1px solid"}:{}
        return(
            <div className="galleryContainer" style={galBorder}>
                <ImageMap
                    data={(this.state.inverted)?[...this.state.data].reverse():this.state.data}
                />
            </div>
        )
    }
}

function ImageMap({data}){
    return(
        <>
        {data.map(function(element) {
            return(
                <>
                {(element.toLowerCase().indexOf("index.ska") === -1)&&
                <div className="genericGalleryItem">
                    <img src={process.env.REACT_APP_CDN_URL+element} className="subGalleryImage"/>
                </div>
                }
                </>
            )
        })}
        </>
    )
}

export default AutoGallery