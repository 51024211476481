import {HashRouter} from "react-router-dom";
import { NavLink } from "../atoms/TextContainers";
import './Nav.css'

function Navigation(){
    return(
        <div className="ham-container">
                <div className="logoBox2">
                    <img src={process.env.REACT_APP_CDN_URL+"grc-logo.png"} alt="grc Logo" className="logo"></img>
                </div>
                <button className="checkbox" onClick={(e) => {e.preventDefault(); e.target.focus()}}></button>
                <div className="hamburger-lines">
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                    <span className="line line3"></span>
                </div>
                <div className="spacer"></div>
                <div className="navContainer">
                    <HashRouter>
                        <NavLink id="club" text="Verein" destination={"/club"}/>
                        <NavLink id="training" text="Training" destination={"/training"}/>
                        <NavLink id="gallery" text="Galerie" destination={"/gallery"}/>
                        <a href="/" className="logoBox">
                            <img src={process.env.REACT_APP_CDN_URL+"grc-logo.png"} alt="grc Logo" className="logo"></img>
                        </a>
                        <NavLink id="spee" text="Spee Cup" destination={"/spee"}/>
                        
                        <NavLink id="sponsors" text="Sponsoren" destination={"/sponsors"}/>
                        <NavLink id="contact" text="Kontakt" destination={"/contact"}/>
                    </HashRouter>
                </div>
            </div>
    );
}

export default Navigation;